import Marquee from "@components/Marquee";
import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import {
	AddToCart,
	AddToPlaylist,
	AddToQueue,
	Play,
} from "@components/interaction";
import { renderArtistNames } from "@components/shared/Artists/ArtistNames";
import LabelLink from "@components/shared/Labels/LabelLink";
import { renderAudioFormatName } from "@lib/constants/audio-format";
import { cls } from "@lib/css";
import { getItemPrice } from "@lib/utils";
import { dynamicImageUrl } from "@lib/utils/dynamicImageUrl";
import { CartItem } from "@models/Cart";
import { Audioformat } from "@models/audio-format";
import { Release } from "@models/release";
import { dataLayerReleaseImpression, pushProductClickEvent, pushProductImpressionEvent } from "@utils/dataLayer";
import { Trans, useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { useCallback, useEffect, useState } from "react";
import { ItemLoader } from "../../loaders";
import {
	Artwork, CartMeta, Exclusive, Item,
	ItemActions,
	ItemButtons, ItemControls, ItemMeta,
	ItemName,
	ItemNumber,
	LoaderWrapper,
	MetaRow, MoreButton,
	Wrapper,
} from "../Lists.shared.style";
import {
	Artists,
} from "./ReleasesList.style";

interface Props {
	location?: string;
	releases?: Release[];
	showNumbers?: boolean;
	direction?: "row" | "column";
	showArtwork?: boolean;
	forceHover?: boolean;
	isPanelList?: boolean;
	cartReleases?: CartItem<Release>[];
	itemCartActions?: (cartItem: CartItem<Release>) => JSX.Element;
	selectedItems?: number[];
	onItemSelectionChange?: (itemId: number, selected: boolean) => void;
	audioFormats?: Record<number, Audioformat>;
	dataTestId?: string;
	formatSelector?: (cartItem: CartItem<Release>) => JSX.Element;
	loadingItems?: number[];
}

const ReleasesList: React.FC<Props> = ({
	location,
	releases = [],
	showNumbers,
	direction = "row",
	showArtwork,
	forceHover,
	isPanelList,
	cartReleases,
	itemCartActions,
	selectedItems,
	onItemSelectionChange,
	audioFormats = {},
	dataTestId,
	formatSelector,
	loadingItems,
}) => {
	const [controls, setControls] = useState<number | undefined>();
	const { t } = useTranslation("translation");
	const exclusive = `${t("Exclusive")}`;

	const filteredReleases = cartReleases ?
		cartReleases
			.filter((r) => r.item !== undefined)
			.map((c) => {
				c.item.cart_item_data = c;
				return c.item;
			}) :
		releases;

	const getProductEventData = useCallback(dataLayerReleaseImpression, [location]);

	useEffect(() => {
		if (filteredReleases && filteredReleases.length > 0) {
			pushProductImpressionEvent({
				ecommerce: {
					currencyCode: filteredReleases[0]?.price?.code || "",
					impressions: filteredReleases.map((release, index) => getProductEventData({ release, index, location })),
				},
			});
		}
	}, []);

	const isCartList = cartReleases !== undefined;

	const handleReleaseClick = (release: Release, index: number) => {
		pushProductClickEvent({
			ecommerce: {
				currencyCode: release?.price?.code || "",
				click: {
					actionField: {
						list: location || "",
					},
					products: [getProductEventData({ release, index, location })],
				},
			},
		});
	};

	return (
		<Wrapper className={cls(direction, isPanelList ? "panel-list" : undefined)}>
			{filteredReleases.map((release, index) => {
				const itemLoading = release.cart_item_data && loadingItems?.includes(release.cart_item_data?.id);
				return (
					<Item
						key={`list-release-${release.id}`}
						className={cls(
							controls && controls === release.id ? "actions" : undefined,
							!showArtwork ? "no-artwork" : undefined,
							forceHover ? "force-hover" : undefined,
							isCartList ? "cart-list" : "partial-artwork",
						)}
						data-testid={dataTestId}
					>
						{showArtwork && (
							<Link
								href={`/release/${release.slug}/${release.id}`}
								prefetch={false}
								title={release.name}
								className="artwork"
								onClick={() => handleReleaseClick(release, index)}
							>
								<Artwork $withTrim={isCartList ? false : true}>
									<Image
										src={dynamicImageUrl({ imageUri: release.image?.uri, size: "sm" })}
										alt={release.name}
										width={isCartList ? 80 : 50}
										height={80}
									/>
									{release.exclusive && <Exclusive>{exclusive.toUpperCase()}</Exclusive>}
								</Artwork>
							</Link>
						)}

						<ItemMeta>
							{!isCartList && showNumbers &&
								(
									<>
										<ItemNumber data-testid="track-number">{index + 1}</ItemNumber>
										<div className="play-hover">
											<Play releaseId={release.id} />
										</div>
									</>
								)}
							{isCartList && release.cart_item_data && onItemSelectionChange && (
								<ItemNumber
									onClick={() => {
										onItemSelectionChange(
											release.cart_item_data?.id || 0,
											!selectedItems?.some((i) => i === release.cart_item_data?.id),
										);
									}}
								>
									{selectedItems?.some((i) => i === release.cart_item_data?.id) ?
											(
												<SpriteIcon id="checkbox-selected" width="20" height="20" />
											) :
											(
												<SpriteIcon id="checkbox" width="20" height="20" />
											)}
								</ItemNumber>
							)}
							<MetaRow>
								<Link
									href={`/release/${release.slug}/${release.id}`}
									prefetch={false}
									title={release.name}
									onClick={() => handleReleaseClick(release, index)}
								>
									<Marquee overlayElementSelector={`[data-overlayfor="release-${release.id}"]`} shouldWrap={false}>
										<ItemName>{release.name} </ItemName>
									</Marquee>
								</Link>
								{release.artists && release.artists.length > 0 && (
									<Marquee overlayElementSelector={`[data-overlayfor="release-${release.id}"]`} shouldWrap={false}>
										<Artists>
											{renderArtistNames(release.artists, { location })}
										</Artists>
									</Marquee>
								)}
								{!isCartList && (
									<Marquee overlayElementSelector={`[data-overlayfor="release-${release.id}"]`} shouldWrap={false}>
										<LabelLink label={release.label} location={location} withMarquee shouldMarqueeWrap={false} />
									</Marquee>
								)}
								{isCartList && (
									<CartMeta>
										<span>
											{release.cart_item_data &&
												getItemPrice({ item: release.cart_item_data, audioFormatsObject: audioFormats })}
										</span>
										<span>
											<Trans>
												{renderAudioFormatName(
													{ audioFormats: Object.values(audioFormats), audioFormatId: release.cart_item_data?.audio_format_id, purchaseTypeId: undefined, item: release },
												)}
											</Trans>
										</span>
									</CartMeta>
								)}
							</MetaRow>
							<ItemActions data-overlayfor={`release-${release.id}`}>
								{isCartList ?
										(
											itemLoading ?
													(
														<LoaderWrapper>
															<ItemLoader />
														</LoaderWrapper>
													) :
													(
														<div className="cart-actions">
															<span className="playlist">
																<AddToPlaylist releaseId={release.id} />
															</span>
															{itemCartActions && release.cart_item_data && (
																<>
																	<div>
																		{itemCartActions(release.cart_item_data)}
																	</div>
																	<div>
																		{formatSelector && formatSelector(release.cart_item_data)}
																	</div>
																</>
															)}
														</div>

													)
										) :
										(
											<div className="actions">
												<span className="play">
													<Play releaseId={release.id} />
												</span>
												<span className="queue">
													<AddToQueue releaseId={release.id} />
												</span>
												<span className="playlist">
													<AddToPlaylist releaseId={release.id} />
												</span>
												<span className="card">
													<AddToCart location={location} release={release} />
												</span>
											</div>
										)}
							</ItemActions>
						</ItemMeta>
						<ItemControls>
							<ItemButtons>
								<MoreButton>
									<Play releaseId={release.id} />
								</MoreButton>
								<MoreButton
									onClick={() => {
										if (controls === release.id)
											setControls(undefined);
										else
											setControls(release.id);
									}}
								>
									<SpriteIcon id="dots" />
								</MoreButton>
							</ItemButtons>
						</ItemControls>
					</Item>
				);
			})}
		</Wrapper>
	);
};

export default ReleasesList;
